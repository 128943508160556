.container-tipo-libro {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 80px 40px;
    padding-top: 80px;
    justify-content: center;
}

.container-tipo-libro .card {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 350px;
    max-width: 100%;
    height: 330px;
    background: white;
    border-radius: 20px;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -ms-transition: 0.5s;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    -ms-box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    flex: 33% 1;
}

.container-tipo-libro .column-card .card:hover .content {
    height: 283px;
}

.container-tipo-libro .card:hover {
    height: 410px;
}

.container-tipo-libro .card .img-box {
    position: absolute;
    top: 20px;
    width: 300px;
    height: 220px;
    border-radius: 12px;
    overflow: hidden;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -ms-transition: 0.5s;
}

.container-tipo-libro .card:hover .img-box {
    top: -100px;
    scale: 0.75;
    border-radius: 50%;
    width: auto;
}

.container-tipo-libro .card .content {
    position: absolute;
    top: 252px;
    width: 100%;
    height: 65px;
    padding: 0 30px;
    text-align: center;
    overflow: hidden;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -ms-transition: 0.5s;
}

.container-tipo-libro .card .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.container-tipo-libro .card:hover .content {
    top: 110px;
    height: 163px;
}

.container-tipo-libro .card .content h2 {
    font-size: 1.4rem;
    font-weight: 700;
    color: var(--color-700);
}

.container-tipo-libro .card .content p {
    color: #1c1c1c;
    font-size: 1.1rem;
    margin-top: 0.3rem;
}

.container-tipo-libro .card .content a {
    display: inline-block;
    padding: 12px 25px;
    text-decoration: none;
    background: var(--clr);
    color: white;
    font-weight: 500;
    color: #51bbdb;
    z-index: 9999;
    position: relative;
}

.container-tipo-libro .column-card {
    width: 100%;
    display: inherit;
    gap: 40px;
    height: 300px;
}

.container-tipo-libro .card .content a:hover {
    opacity: 0.8;
}

.img-box img {
    width: 220px;
    margin: auto;
    border-radius: 50%;
    height: 220px;
    object-fit: cover;
}


.column-card:nth-child(1) .img-box img,
.cards-section .corregir-margin div:nth-child(1) .contenedor_tarjeta img.fluid {
    object-position: right;
}

.column-card .card:nth-child(3) .img-box img,
.cards-section .corregir-margin div:nth-child(3) .contenedor_tarjeta img.fluid {
    object-position: 30% 100%;
}

section#tipo-libros{
    height: 630px;
}

@media (max-width: 1536px) {

    .container-tipo-libro .column-card .card:hover .content {
        height: 336px;
    }
    
    .container-tipo-libro .card:hover {
        height: 470px;
    }

    section#tipo-libros{
        height: 680px;
    }
}

@media (max-width: 1279px) {

    .container-tipo-libro .column-card .card:hover {
        height: 530px;
    }

    .container-tipo-libro .column-card .card:hover .content {
        height: 406px;
    }

    section#tipo-libros{
        height: 740px;
    }
    .container-tipo-libro {
        gap: 80px 40px;
    }

    .container-tipo-libro .card .content {
        padding: 0 20px;
    }
}

@media (max-width: 1023px) {
    section#tipo-libros {
        padding: 2rem 1rem 4rem;
        height: auto;
    }
}


@media (max-width: 1023px) {
    .container-tipo-libro {
        display: none;
    }

    .remplazohref {
        text-decoration: none;
    }

    .cards-section figure img {
        width: 300px;
        height: 300px;
        object-fit: cover;
        border-radius: 50%;
        margin: auto;
    }

    .cards-section figure h4 {
        font-size: 1.4rem;
        font-weight: 700;
        letter-spacing: 0.5px;
    }

    .contenedor {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 0.5rem 2rem;
    }

    .contenedor_tarjeta {
        width: 100%;
        perspective: 1000px;
    }

    figure {
        position: relative;
        transition: all ease .5s;
        -webkit-transition: all ease .5s;
        -ms-transition: all ease .5s;
        transform-style: preserve-3d;
        -webkit-transform-style: preserve-3d;
        -ms-transform-style: preserve-3d;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 20px !important;
        border-radius: 20px !important;
        width: fit-content;
        margin: auto;
    }

    figure .frontal,
    figure .trasera {
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }

    .frontal h4 {
        color: #5c2431;
        margin-top: 0.8rem;
    }

    figure .frontal {
        -webkit-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
    }


    figure .trasera {
        position: absolute;
        top: 0;
        padding: 20px;
        color: #fff;
        transform: perspective(600px) rotateY(180deg);
        -webkit-transform: perspective(600px) rotateY(180deg);
        -ms-transform: perspective(600px) rotateY(180deg);
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        display: flex;
        align-content: center;
        align-items: center;
        font-size: 1.2rem;
        line-height: 1.5;
        font-weight: 500;
        border-radius: 20px !important;
        opacity: 2;
    }


    figure .trasera p {
        color: #1e2128;
        font-weight: 400;
        opacity: 1;
        opacity: 2;
        -webkit-transition: opacity 0.6s;
        transition: opacity 0.6s;
        visibility: visible;
    }

    .contenedor_tarjeta:hover figure {
        -webkit-transform: perspective(600px) rotateY(-180deg) translateZ(1px);
        -ms-transform: perspective(600px) rotateY(-180deg) translateZ(1px);
        -o-transform: perspective(600px) rotateY(-180deg) translateZ(1px);
        transform: perspective(600px) rotateY(-180deg) translateZ(1px);
        opacity:1;
        visibility:visible;
    }

    .cards-section {
        margin-top: 5rem;
    }

    .cards-section figure {
        min-height: 300px;
        background: #ffffff;
    }


    .cards-section .contenedor_tarjeta figure {
        -webkit-box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.25);
        -ms-box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.25);
    }

    .cards-section figure .trasera {
        padding: 1rem;
    }

    .cards-section .card img {
        width: 70%;
    }

    .cards-section {
        margin-top: 2rem;
    }

    .card-title.h5 {
        font-size: 1.1rem;
    }

    .card p {
        font-size: 1rem;
    }

    .card-body {
        padding: 1rem 10px;
    }

    .corregir-margin {
        margin: 0;
    }
}

@media (max-width: 575px) {
    .cards-section figure {
        min-height: auto;
    }

    .cards-section figure .trasera {
        font-size: 1rem;
        padding: 1rem;
    }

    .cards-section figure img {
        width: 230px;
        height: 230px;
    }
}