.header {
    background-color: white;
    color: var(--color-700);
}

.logo-header {
    height: 7.5rem;
}

@media (max-width: 991px){
    .flow-root{
        text-align: center;
    }
}


@media (max-width: 639px){
    .logo-header {
        height: 5rem;
    }    
}
