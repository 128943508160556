.intro .column {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.intro.container {
    display: flex;
    justify-content: center;
}


.intro .columns {
    min-height: 600px;
}

.intro .title {
    text-align: initial;
    font-size: 3.6rem;
    width: 100%;
}

.intro .column div {
    width: 100%;
    text-align: initial;
}

.intro .button {
    padding: 1.5rem 3rem;
}

.video-intro video {
    border-radius: 50%;
    width: 450px !important;
    height: 450px !important;
    margin: auto;
}

.intro h1 {
    text-align: start;
    color: rgb(241, 241, 241);
}

.intro h2 {
    text-align: start;
    color: rgb(180, 180, 180);
}

.intro-background {
    background: #5c2431;
    padding-bottom: 6rem;
    padding-top: 6rem;
}

.contactanos-home li {
    font-size: 1.2rem !important;
}

@media (max-width: 1279px) {

    .video-intro video {
        width: 100% !important;
        height: 100% !important;
    }

}

@media (max-width: 1023px) {
    .intro .columns {
        min-height: 400px;
        padding: 0 2rem;
    }

    .intro .columns-2.d-flex-center {
        gap: 20px
    }

    .video-intro video {
        border-radius: 0%;
    }

    .column-card{
        display: block;
    }





    
}

@media (max-width: 991px) {
    .intro .column .title {
        font-size: 2.5rem;
    }
}

@media (max-width: 768px) {

    .intro .title,
    .intro .column div {
        text-align: center;
    }

    .intro .columns {
        padding: 0rem;
    }

    .intro h1,
    .intro h2 {
        text-align: center;
    }

    .intro-background{
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .video-intro video {
        border-radius: 50%;
        width: 50% !important;
    }

    .intro .columns-2.d-flex-center{
        flex-direction: column;
    }
}

@media (max-width: 639px){
    .intro h1{
        font-size: 2rem;
    }
    .intro h2 {
        font-size: 1.25rem;
        line-height: 1.6rem;
    }

}
