.faq-section h1 {
  text-align: center;
  color: #1569a8;
}
.faq-section .btn {
  display: inline-block;
  margin-bottom: 20px;
  border: 1px solid #1569a8;
  background: white;
  color: #1569a8;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 5px;
  font-size: 15px;
  cursor: pointer;
  outline: none;
}
.faq-section .btn:hover {
  background: #1569a8;
  color: white;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
}

.accordion {
  width: 100%;
  max-width: 800px;
  margin: 3rem auto 5rem;
}

.item-faq:nth-child(1) .title {
  border-radius: 20px 20px 0 0;
}

.accordion .title {
  padding: 30px 30px;
  cursor: pointer;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  color: white;
  position: relative;
  font-size: 20px;
  background: #5c2332;
  margin-bottom: -1px;
  border-bottom: 1px solid #5c2332;
  text-align: left;
  font-weight: 600;
  padding-right: 60px;
}
.accordion .title::after {
  content: "+";
  font-size: 30px;
  color: white;
  transition: transform 0.5s ease-in-out;
  -webkit-transition: transform 0.5s ease-in-out;
  -ms-transition: transform 0.5s ease-in-out;
  position: absolute;
  right: 30px;
  font-family: monospace;
  top: 21px;
}
.accordion .title.is-expanded {
  transition: background 0.5s;
  -webkit-transition: background 0.5s;
  -ms-transition: background 0.5s;
  background: #5c2332;
}
.accordion .title.is-expanded::after {
  content: "-";
  transform: rotate(-360deg);
  -webkit-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
}
.accordion .content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s;
  -webkit-transition: max-height 0.5s;
  -ms-transition: max-height 0.5s;
  margin: 0;
  padding: 0 30px;
  border: solid 1px #eeeeee;
  border-top: 0;
  background: #e9e9e9;
}

.item-faq:nth-child(2) .title{
  border-radius: 0 0 15px 15px;
}


.item-faq:nth-child(2) .content{
  margin-top: -15px;
}

.item-faq:nth-child(2) .content p{
  padding-top: 45px;
}

.accordion .content p {
  padding: 30px 0;
  margin: 0;
  opacity: 0;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -ms-transition: 0.5s;
  font-size: 1.1rem;
  font-weight: 500;
}
.accordion .content.is-expanded {
  max-height: 500px;
  overflow: hidden;
}
.accordion .content.is-expanded p {
  opacity: 1;
}
.accordion .item-faq:not(:last-child){
  border-bottom: 3px solid #231f20;
}

@media (max-width: 768px) {
  .accordion {
    margin: 1.5rem auto 3rem;
  }
}