section.clientes-accordion {
    display: flex;
    gap: 10px;
    cursor: pointer;
}

.clientes-accordion article {
    position: relative;
    overflow: hidden;
    background: #ffffff;
    width: 120px;
    height: 500px;
    border-radius: 36px;
    display: flex;
    align-items: flex-end;
    opacity: 0.9;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -ms-transition: 0.5s;
}

.clientes-accordion h2,
.clientes-accordion p {
    margin: 0;
}

.clientes-accordion article h2 {
    font-size: 44px;
    font-weight: 700;
    color: rgb(255 255 255 / 96%);
}

.clientes-accordion article p {
    color: rgb(255 255 255 / 66%);
}

.clientes-accordion article.active {
    width: 100%;
    opacity: 1;
}

.clientes-accordion article .material-symbols-outlined {
    display: grid;
    place-items: center;
    width: 130px;
    height: 50px;
    background: rgb(255 255 255 / 80%);
    border-radius: 50%;
    font-size: 28px;
}

.clientes-accordion article .content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    padding: 100px 0 20px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 14px;
    background: linear-gradient(to bottom, rgb(0 0 0 / 0%), rgb(0 0 0 / 100%));
    transition: 0.25s;
    -webkit-transition: 0.25s;
    -ms-transition: 0.25s;
}

.clientes-accordion article.active .content {
    opacity: 1;
    visibility: visible;
}

.clientes-accordion article:nth-child(4) img {
    object-position: right bottom;
}

.clientes-accordion article img {
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    height: 150%;
    filter: grayscale(0.5);
    filter: blur(1px);
    object-fit: cover;
}

.clientes-accordion article.active img {
    filter: blur(0px);
}

.clientes-accordion .logo {
    height: 6em;
    padding: 1.5em;
    will-change: filter;
    transition: filter 300ms;
    -webkit-transition: filter 300ms;
    -ms-transition: filter 300ms;
}

.clientes-accordion .logo:hover {
    filter: drop-shadow(0 0 2em #646cffaa);
}

.clientes-accordion .logo.react:hover {
    filter: drop-shadow(0 0 2em #61dafbaa);
}

@keyframes logo-spin {
    from {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
    }
}

@media (prefers-reduced-motion: no-preference) {
    .clientes-accordion a:nth-of-type(2) .logo {
        animation: logo-spin infinite 20s linear;
    }
}

.clientes-accordion .card {
    padding: 2em;
}

.clientes-accordion .read-the-docs {
    color: #888;
}

@media (max-width: 1023px) {
    section.clientes-accordion{
        flex-direction: column;
    }

    .clientes-accordion article {
        height: 40px !important;
        width: 100% !important;
    }

    .clientes-accordion article img{
        height: auto !important;
    }

    .clientes-accordion article.active {
        height: 330px !important;
    }
}

@media (max-width: 767px) {
    .clientes-accordion article.active img {
        height: 100% !important;
        width: 100%;
    }
}